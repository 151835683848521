function Message() {
  return (
    <div className="col-xl-9 col-lg-9 col-md-12">
      <div className="dashboard__message__content__main">
        <div className="dashboard__message__content__main__title dashboard__message__content__main__title__2">
          <h3>Messages</h3>
        </div>
        <div className="dashboard__meessage__wraper">
          <div className="row">
            <div className="col-xl-5 col-lg-6 col-md-12 col-12">
              <div className="dashboard__meessage">
                <div className="dashboard__meessage__chat">
                  <h3>Chats</h3>
                </div>
                <div className="dashboard__meessage__search">
                  <button>
                    <i className="icofont-search-1" />
                  </button>
                  <input type="text" placeholder="Search" />
                </div>
                <div className="dashboard__meessage__contact">
                  <ul>
                    <li>
                      <div className="dashboard__meessage__contact__wrap">
                        <div className="dashboard__meessage__chat__img">
                          <span className="dashboard__meessage__dot online" />
                          <img
                            src="../img/teacher/teacher__1.png"
                            alt="instructor"
                          />
                        </div>
                        <div className="dashboard__meessage__meta">
                          <h5>Rex Allen</h5>
                          <p className="preview">Hey, How are you?</p>
                          <span className="chat__time">12 min</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="dashboard__meessage__contact__wrap">
                        <div className="dashboard__meessage__chat__img">
                          <span className="dashboard__meessage__dot online" />
                          <img
                            src="../img/teacher/teacher__2.png"
                            alt="instructor"
                          />
                        </div>
                        <div className="dashboard__meessage__meta">
                          <h5>Rex Allen</h5>
                          <p className="preview">Hey, How are you?</p>
                          <span className="chat__time">4:35pm</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="dashboard__meessage__contact__wrap">
                        <div className="dashboard__meessage__chat__img">
                          <span className="dashboard__meessage__dot online" />
                          <img
                            src="../img/teacher/teacher__3.png"
                            alt="instructor"
                          />
                        </div>
                        <div className="dashboard__meessage__meta">
                          <h5>Julia Jhones</h5>
                          <p className="preview">Hey, How are you?</p>
                          <span className="chat__time">1:40pm</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="dashboard__meessage__contact__wrap">
                        <div className="dashboard__meessage__chat__img">
                          <span className="dashboard__meessage__dot online" />
                          <img
                            src="../img/teacher/teacher__4.png"
                            alt="instructor"
                          />
                        </div>
                        <div className="dashboard__meessage__meta">
                          <h5>Anderson</h5>
                          <p className="preview">Hey, How are you?</p>
                          <span className="chat__time">3:20am</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="dashboard__meessage__contact__wrap">
                        <div className="dashboard__meessage__chat__img">
                          <span className="dashboard__meessage__dot online" />
                          <img
                            src="../img/teacher/teacher__5.png"
                            alt="instructor"
                          />
                        </div>
                        <div className="dashboard__meessage__meta">
                          <h5>Rex Allen</h5>
                          <p className="preview">Hey, How are you?</p>
                          <span className="chat__time">12 min</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="dashboard__meessage__contact__wrap">
                        <div className="dashboard__meessage__chat__img">
                          <span className="dashboard__meessage__dot online" />
                          <img
                            src="../img/teacher/teacher__6.png"
                            alt="instructor"
                          />
                        </div>
                        <div className="dashboard__meessage__meta">
                          <h5>Rex Allen</h5>
                          <p className="preview">Hey, How are you?</p>
                          <span className="chat__time">12 min</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="dashboard__meessage__contact__wrap">
                        <div className="dashboard__meessage__chat__img">
                          <span className="dashboard__meessage__dot online" />
                          <img
                            src="../img/teacher/teacher__2.png"
                            alt="instructor"
                          />
                        </div>
                        <div className="dashboard__meessage__meta">
                          <h5>Rex Allen</h5>
                          <p className="preview">Hey, How are you?</p>
                          <span className="chat__time">4:35pm</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="dashboard__meessage__contact__wrap">
                        <div className="dashboard__meessage__chat__img">
                          <span className="dashboard__meessage__dot online" />
                          <img
                            src="../img/teacher/teacher__1.png"
                            alt="instructor"
                          />
                        </div>
                        <div className="dashboard__meessage__meta">
                          <h5>Julia Jhones</h5>
                          <p className="preview">Hey, How are you?</p>
                          <span className="chat__time">1:40pm</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-7 col-lg-6 col-md-12 col-12">
              <div className="dashboard__meessage__content__wrap">
                <div className="dashboard__meessage__profile">
                  <div className="dashboard__meessage__profile__img">
                    <img src="../img/teacher/teacher__2.png" alt="instructor" />
                  </div>
                  <div className="dashboard__meessage__profile__meta">
                    <h5>Bradshaw</h5>
                    <p>Stay at home, Stay safe</p>
                  </div>
                  <div className="dashboard__meessage__profile__chat__option">
                    <a href="admin-dashboard.html">
                      <i className="icofont-phone" />
                    </a>
                    <a href="admin-dashboard.html">
                      <i className="icofont-ui-video-chat" />
                    </a>
                  </div>
                </div>
                <div className="dashboard__meessage__sent">
                  <ul>
                    <li>
                      <div className="dashboard__meessage__sent__item__img">
                        <img
                          src="../img/teacher/teacher__1.png"
                          alt="instructor"
                        />
                      </div>
                      <div className="dashboard__meessage__sent__item__content">
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          sed.
                        </p>
                        <span className="time">4:32 PM</span>
                        <p>Dolor sit amet consectetur</p>
                        <span className="time">4:30 PM</span>
                      </div>
                    </li>
                    <li className="dashboard__meessage__sent__item">
                      <div className="dashboard__meessage__sent__item__content">
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          sed.
                        </p>
                        <span className="time">4:40 PM</span>
                        <p>Dolor sit amet consectetur</p>
                        <span className="time">4:42 PM</span>
                      </div>
                      <div className="dashboard__meessage__sent__item__img">
                        <img
                          src="../img/teacher/teacher__3.png"
                          alt="instructor"
                        />
                      </div>
                    </li>
                    <li className="sent">
                      <div className="dashboard__meessage__sent__item__img">
                        <img
                          src="../img/teacher/teacher__4.png"
                          alt="instructor"
                        />
                      </div>
                      <div className="dashboard__meessage__sent__item__content">
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          sed.
                        </p>
                        <span className="time">5:01 PM</span>
                        <p>Dolor sit amet consectetur</p>
                        <span className="time">5:03 PM</span>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="dashboard__meessage__input">
                  <input type="text" placeholder="Type something" />
                  <i
                    className="icofont-attachment attachment"
                    aria-hidden="true"
                  />
                  <button className="submit">
                    <i className="icofont-arrow-right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Message;
