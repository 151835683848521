import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

function ProgramImplementation() {
  let isError = false;
  const [mode, setMode] = useState("add");

  const [activeId, setActiveId] = useState("");
  const [programs, setPrograms] = useState([]);
  const [courseId, setCourseId] = useState("");
  const [courseName, setCourseName] = useState("");
  const [implementation, setImplementation] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    let fieldName = [];
    if (!courseId.length) {
      fieldName.push("First Name");
    }
    if (!courseName.length) {
      fieldName.push("Course Name");
    }
    if (!implementation.length) {
      fieldName.push("Implementation");
    }

    if (fieldName.length) {
      for (const field of fieldName) {
        toast.error(field + " is Required!");
      }
      isError = true;
    }

    if (isError) {
      return;
    }
    mode === "add" ? addProgram() : updateProgram();
  };

  const clearFields = () => {
    setCourseId("");
    setCourseName("");
    setImplementation("");
  };

  const addProgram = () => {
    const newProgram = {
      courseId: courseId,
      courseName: courseName,
      implementation: implementation,
    };

    setPrograms((prevPrograms) => [...prevPrograms, newProgram]);

    clearFields();
  };

  const editProgram = (courseId) => {
    setMode("edit");
    setActiveId(courseId);
    const program = programs.find((program) => program.courseId === courseId);
    setCourseId(program.courseId);
    setCourseName(program.courseName);
    setImplementation(program.implementation);
  };

  const deleteProgram = (id) => {
    const newPrograms = programs.filter((program) => program.courseId !== id);
    setPrograms(newPrograms);
  };
  const updateProgram = () => {
    const program = programs.find((program) => program.courseId === activeId);
    program.courseId = courseId;
    program.courseName = courseName;
    program.implementation = implementation;

    clearFields();
    setMode("add");
  };

  return (
    <div className="dashboard__content__wraper">
      <div className="dashboard__section__title">
        <h4>Program Implementation</h4>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="row">
            <div className="col-xl-6">
              <div className="dashboard__form__wraper">
                <div className="dashboard__form__input">
                  <label>Course ID</label>
                  <input
                    type="text"
                    onChange={(e) => setCourseId(e.target.value)}
                    value={courseId}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="dashboard__form__wraper">
                <div className="dashboard__form__input">
                  <label>Course Name</label>
                  <input
                    type="text"
                    onChange={(e) => setCourseName(e.target.value)}
                    value={courseName}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="dashboard__form__wraper">
                <div className="dashboard__form__input">
                  <label>Implementation</label>
                  <textarea
                    name="implementation"
                    id="implementation"
                    cols={30}
                    rows={3}
                    onChange={(e) => setImplementation(e.target.value)}
                    value={implementation}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="dashboard__form__button">
                <Link
                  className="default__button"
                  onClick={(e) => handleSubmit(e)}
                >
                  {mode === "add" ? "Add" : "Edit"} Implementation
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {programs.length > 0 && (
        <div className="row">
          <div className="col-xl-12 mt-5">
            <div className="dashboard__table table-responsive">
              <table>
                <thead>
                  <tr>
                    <th>Course ID</th>
                    <th>Course Name</th>
                    <th>Implementation</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {programs.map((program) => (
                    <tr key={program.courseId}>
                      <td>{program.courseId}</td>
                      <td>{program.courseName}</td>
                      <td>{program.implementation}</td>
                      <td>
                        <div className="dashboard__button__group">
                          <Link
                            className="dashboard__small__btn__2"
                            onClick={() => editProgram(program.courseId)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-edit"
                            >
                              <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                              <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
                            </svg>
                            &nbsp; Edit
                          </Link>
                          <Link
                            className="dashboard__small__btn__2 dashboard__small__btn__3"
                            onClick={() => deleteProgram(program.courseId)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-trash-2"
                            >
                              <polyline points="3 6 5 6 21 6" />
                              <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                              <line x1={10} y1={11} x2={10} y2={17} />
                              <line x1={14} y1={11} x2={14} y2={17} />
                            </svg>
                            &nbsp; Delete
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProgramImplementation;
