import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import CreatableSelect from "react-select/creatable";

function CourseContent() {
  let isError = false;
  const [mode, setMode] = useState("create");
  const [id, setId] = useState(1);
  const [activeId, setActiveId] = useState(-1);
  const [courses, setCourses] = useState([]);
  const [courseName, setCourseName] = useState("");
  const [sections, setSections] = useState([]);
  const [sectionTitle, setSectionTitle] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    let fieldName = [];
    if (!courseName.length) {
      fieldName.push("Course Name");
    }

    if (fieldName.length) {
      for (const field of fieldName) {
        toast.error(field + " is Required!");
      }
      isError = true;
    }
    if (isError) {
      return;
    }
    mode === "create" ? createCourse() : updateCourse();
  };

  const clearFields = () => {
    setCourseName("");
    setSections([]);
    setSectionTitle("");
    setMode("create");
  };

  const createCourse = () => {
    const newCourse = {
      id: id,
      courseName: courseName,
      sections: sections,
    };

    setCourses((prevCourses) => [...prevCourses, newCourse]);

    clearFields();
    setId(id + 1);
  };

  const addSection = () => {
    if (sectionTitle.trim()) {
      setSections((prevSections) => [
        ...prevSections,
        { title: sectionTitle, videoTitles: [] },
      ]);
      setSectionTitle("");
    }
  };

  const editCourseContent = (id) => {
    setMode("edit");
    setActiveId(id);
    const course = courses.find((course) => course.id === id);
    setCourseName(course.courseName);
    setSections(course.sections);
  };

  const deleteCourseContent = (id) => {
    const newCourse = courses.filter((course) => course.id !== id);
    setCourses(newCourse);
  };

  const updateCourse = () => {
    const course = courses.find((course) => course.id === activeId);
    course.courseName = courseName;
    course.sections = sections;

    clearFields();
  };

  const selectedOptions = [];
  const customOptions = [];

  const options = [...selectedOptions, ...customOptions];
  return (
    <div className="dashboard__content__wraper">
      <div className="dashboard__section__title">
        <h4>Course Content</h4>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Course Name
                </label>
                <input
                  type="text"
                  name="course-name"
                  className="form-control"
                  onChange={(e) => setCourseName(e.target.value)}
                  value={courseName}
                />
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="dashboard__form__wraper">
              <div className="dashboard__form__input">
                <label>Section Title</label>
                <input
                  type="text"
                  name="section-title"
                  className="form-control"
                  onChange={(e) => setSectionTitle(e.target.value)}
                  value={sectionTitle}
                />
              </div>
              <button onClick={addSection} className="btn btn-primary">
                Add Section
              </button>
              {sections.map((section, sectionIndex) => (
                <div
                  key={"section-" + section.title}
                  className="video-title-form mt-4"
                >
                  <div className="form-group">
                    <label className="mont-font fw-600 font-xss">
                      Video Titles for Section: {section.title}
                    </label>
                    <CreatableSelect
                      name={`video-title-${sectionIndex}`}
                      isMulti
                      onChange={(selectedOptions) => {
                        const updatedSections = [...sections];
                        updatedSections[sectionIndex].videoTitles =
                          selectedOptions.map((option) => option.value);
                        setSections(updatedSections);
                      }}
                      options={options}
                    />
                  </div>
                </div>
              ))}
              <div className="col-xl-12 mt-4">
                <div className="dashboard__form__button pull-right">
                  <Link
                    className="default__button"
                    onClick={(e) => handleSubmit(e)}
                  >
                    {mode === "create" ? "Create" : "Edit"} Course Content
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {courses.length > 0 && (
          <div className="row">
            <div className="col-xl-12 mt-5">
              <div className="dashboard__table table-responsive">
                <table>
                  <thead>
                    <tr>
                      <th>Course ID</th>
                      <th>Course Name</th>
                      <th>Course Content</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {courses.map((course) => (
                      <tr key={course.id}>
                        <td>{course.id}</td>
                        <td>{course.courseName}</td>
                        <td>
                          <ul>
                            {course.sections.map((section, sectionIndex) => (
                              <li key={"table-section-" + section.title}>
                                <strong>Section:</strong> {section.title}
                                <br />
                                <strong>Video Titles:</strong>{" "}
                                {section.videoTitles.join(", ")}
                              </li>
                            ))}
                          </ul>
                        </td>
                        <td>
                          <div className="dashboard__button__group">
                            <Link
                              className="dashboard__small__btn"
                              onClick={() => editCourseContent(course.id)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-edit"
                              >
                                <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                                <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
                              </svg>{" "}
                              Edit
                            </Link>
                            <Link
                              className="dashboard__small__btn"
                              onClick={() => deleteCourseContent(course.id)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-trash-2"
                              >
                                <polyline points="3 6 5 6 21 6" />
                                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                                <line x1={10} y1={11} x2={10} y2={17} />
                                <line x1={14} y1={11} x2={14} y2={17} />
                              </svg>{" "}
                              Delete
                            </Link>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default CourseContent;
