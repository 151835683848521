import React, { useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";

function ManageCourses(props) {
  const [studentName, setStudentName] = useState("");
  const [studentId, setStudentId] = useState("");
  const [courses] = useState([
    { value: "Operating Systems", label: "Operating Systems" },
    { value: "Networking", label: "Networking" },
    { value: "Algorithms", label: "Algorithms" },
    { value: "Compiler Design", label: "Compiler Design" },
    { value: "System Design", label: "System Design" },
  ]);

  const [selectedCourses, setSelectedCourses] = useState([]);
  let isError = false;
  const [mode, setMode] = useState("add");

  const [activeId, setActiveId] = useState(-1);
  const [students, setStudents] = useState([]);
  const heading = props.heading;
  const role = props.role;
  const action = props.action;

  const handleCourseSelection = (selectedValues) => {
    setSelectedCourses(selectedValues);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let fieldName = [];
    if (!studentId.length) {
      fieldName.push(role + " ID");
    }
    if (!studentName.length) {
      fieldName.push("Name");
    }
    if (selectedCourses.length === 0) {
      fieldName.push("Selected Courses");
    }
    if (fieldName.length) {
      for (const field of fieldName) {
        toast.error(field + " is Required!");
      }
      isError = true;
    }
    if (isError) {
      return;
    }
    if (mode === "add") {
      addStudentCourse();
    } else {
      updateStudentCourse();
    }
  };

  const clearFields = () => {
    setStudentName("");
    setStudentId("");
    setSelectedCourses([]);
  };

  const addStudentCourse = () => {
    const newStudent = {
      id: studentId,
      studentName: studentName,
      selectedCourses: selectedCourses,
    };
    setStudents((prevStudents) => [...prevStudents, newStudent]);
    clearFields();
  };

  const editStudentCourse = (id) => {
    setMode("edit");
    setActiveId(id);
    const student = students.find((student) => student.id === id);
    setStudentName(student.studentName);
    setStudentId(student.id);
    setSelectedCourses(student.selectedCourses);
  };

  const deleteStudentCourse = (id) => {
    const newStudents = students.filter((student) => student.id !== id);
    setStudents(newStudents);
    clearFields();
    setMode("add");
  };

  const updateStudentCourse = () => {
    const student = students.find((student) => student.id === activeId);
    student.studentName = studentName;
    student.id = studentId;
    student.selectedCourses = selectedCourses;
    clearFields();
    setMode("add");
  };

  return (
    <div className="dashboard__content__wraper">
      <div className="dashboard__section__title">
        <h4>{heading}</h4>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="row">
            <div className="col-xl-6">
              <div className="dashboard__form__wraper">
                <div className="dashboard__form__input">
                  <label>{role} ID</label>
                  <input
                    type="text"
                    onChange={(e) => setStudentId(e.target.value)}
                    value={studentId}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="dashboard__form__wraper">
                <div className="dashboard__form__input">
                  <label>{role} Name</label>
                  <input
                    type="text"
                    onChange={(e) => setStudentName(e.target.value)}
                    value={studentName}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="dashboard__form__wraper">
                <div className="dashboard__form__input">
                  <label>Courses</label>
                  <Select
                    isMulti
                    options={courses}
                    onChange={handleCourseSelection}
                    value={selectedCourses}
                    placeholder="Select Courses"
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="dashboard__form__button pull-right">
                <Link
                  className="default__button"
                  onClick={(e) => handleSubmit(e)}
                >
                  {mode === "add" ? "Add" : "Edit"} {action}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {students.length > 0 && (
        <div className="row">
          <div className="col-xl-12 mt-5">
            <div className="dashboard__table table-responsive">
              <table>
                <thead>
                  <tr>
                    <th>{role} ID</th>
                    <th>Name</th>
                    <th>Courses Registered</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {students.map((student) => (
                    <tr key={student.id}>
                      <td>
                        <p>{student.id}</p>
                      </td>
                      <td>
                        <p>{student.studentName}</p>
                      </td>
                      <td>
                        <p>
                          {student.selectedCourses
                            .map((course) => course.label)
                            .join(", ")}
                        </p>
                      </td>
                      <td>
                        <div className="dashboard__button__group">
                          <Link
                            className="dashboard__small__btn__2"
                            onClick={() => editStudentCourse(student.id)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-edit"
                            >
                              <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                              <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
                            </svg>
                            &nbsp; Edit
                          </Link>
                          <Link
                            className="dashboard__small__btn__2 dashboard__small__btn__3"
                            onClick={() => deleteStudentCourse(student.id)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-trash-2"
                            >
                              <polyline points="3 6 5 6 21 6" />
                              <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                              <line x1={10} y1={11} x2={10} y2={17} />
                              <line x1={14} y1={11} x2={14} y2={17} />
                            </svg>
                            &nbsp; Delete
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ManageCourses;
