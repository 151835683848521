import { Link } from "react-router-dom";

export default function DashContent() {
  return (
    <div className="col-xl-9 col-lg-9 col-md-12">
      <div className="dashboard__content__wraper">
        <div className="dashboard__section__title">
          <h4>QA Officer Dashboard</h4>
        </div>
        <div className="row">
          <div className="col-xl-4 col-lg-6 col-md-12 col-12">
            <div className="dashboard__single__counter">
              <div className="counterarea__text__wraper">
                <div className="counter__img">
                  <img src="../img/counter/counter__1.png" alt="counter" />
                </div>
                <div className="counter__content__wraper">
                  <div className="counter__number">
                    <span className="counter">900</span>+
                  </div>
                  <p>Enrolled Courses</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6 col-md-12 col-12">
            <div className="dashboard__single__counter">
              <div className="counterarea__text__wraper">
                <div className="counter__img">
                  <img src="../img/counter/counter__2.png" alt="counter" />
                </div>
                <div className="counter__content__wraper">
                  <div className="counter__number">
                    <span className="counter">500</span>+
                  </div>
                  <p>Active Courses</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6 col-md-12 col-12">
            <div className="dashboard__single__counter">
              <div className="counterarea__text__wraper">
                <div className="counter__img">
                  <img src="../img/counter/counter__3.png" alt="counter" />
                </div>
                <div className="counter__content__wraper">
                  <div className="counter__number">
                    <span className="counter">300</span>k
                  </div>
                  <p>Complete Courses</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6 col-md-12 col-12">
            <div className="dashboard__single__counter">
              <div className="counterarea__text__wraper">
                <div className="counter__img">
                  <img src="../img/counter/counter__4.png" alt="counter" />
                </div>
                <div className="counter__content__wraper">
                  <div className="counter__number">
                    <span className="counter">1500</span>+
                  </div>
                  <p>Total Courses</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6 col-md-12 col-12">
            <div className="dashboard__single__counter">
              <div className="counterarea__text__wraper">
                <div className="counter__img">
                  <img src="../img/counter/counter__3.png" alt="counter" />
                </div>
                <div className="counter__content__wraper">
                  <div className="counter__number">
                    <span className="counter">30</span>k
                  </div>
                  <p>Total Students</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6 col-md-12 col-12">
            <div className="dashboard__single__counter">
              <div className="counterarea__text__wraper">
                <div className="counter__img">
                  <img src="../img/counter/counter__4.png" alt="counter" />
                </div>
                <div className="counter__content__wraper">
                  <div className="counter__number">
                    <span className="counter">90,000</span>K+
                  </div>
                  <p>Total Earning</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-6 col-lg-12 col-md-12 col-12">
          <div className="dashboard__content__wraper admin__content__wrapper">
            <div className="dashboard__section__title">
              <h4>Students</h4>
            </div>
            <div className="dashboard__popular__instructor">
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link>Manage Students</Link>
                  </h4>
                </div>
              </div>
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link>Manage Courses</Link>
                  </h4>
                </div>
              </div>

              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link>Reports & Performance</Link>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-12 col-md-12 col-12">
          <div className="dashboard__content__wraper admin__content__wrapper">
            <div className="dashboard__section__title">
              <h4>QA Officers</h4>
            </div>
            <div className="dashboard__popular__instructor">
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link>Manage QA Officers</Link>
                  </h4>
                </div>
              </div>
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link>Assign Course</Link>
                  </h4>
                </div>
              </div>
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link>Reports & Performance</Link>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-12 col-md-12 col-12">
          <div className="dashboard__content__wraper admin__content__wrapper">
            <div className="dashboard__section__title">
              <h4>Instructors</h4>
            </div>
            <div className="dashboard__popular__instructor">
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link>Manage Instructors</Link>
                  </h4>
                </div>
              </div>
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link>Handle Courses</Link>
                  </h4>
                </div>
              </div>
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link>Assign Students</Link>
                  </h4>
                </div>
              </div>
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link>Reviews</Link>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-12 col-md-12 col-12">
          <div className="dashboard__content__wraper admin__content__wrapper">
            <div className="dashboard__section__title">
              <h4>Coordinator</h4>
            </div>
            <div className="dashboard__popular__instructor">
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link>Manage Coordinators</Link>
                  </h4>
                </div>
              </div>
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link>Handle Programs</Link>
                  </h4>
                </div>
              </div>
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link>Assign Support Tickets</Link>
                  </h4>
                </div>
              </div>
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link>Analytics</Link>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
