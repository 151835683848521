import { useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";

function AssignSupportTickets() {
  const [courses] = useState([
    { value: "Operating Systems", label: "Operating Systems" },
    { value: "Networking", label: "Networking" },
    { value: "Algorithms", label: "Algorithms" },
    { value: "Compiler Design", label: "Compiler Design" },
    { value: "System Design", label: "System Design" },
  ]);

  const [coordinators] = useState([
    { value: "Chad Nicolas", label: "Chad Nicolas" },
    { value: "Noah Buckridge", label: "Noah Buckridge" },
    { value: "Sherman Herman", label: "Sherman Herman" },
    { value: "David Durgan", label: "David Durgan" },
    { value: "Hannah Dach", label: "Hannah Dach" },
    { value: "Jesus Hermiston", label: "Jesus Hermiston" },
    { value: "Douglas Hayes PhD", label: "Douglas Hayes PhD" },
    { value: "Merle Gerlach", label: "Merle Gerlach" },
  ]);
  const [supportId, setSupportId] = useState("");
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [selectedCoordinators, setSelectedCoordinators] = useState([]);
  const [supportTickets, setSupportTickets] = useState([]);

  let isError = false;
  const [mode, setMode] = useState("assign");
  const [activeId, setActiveId] = useState(-1);

  const handleCourseSelection = (selectedValues) => {
    setSelectedCourses(selectedValues);
  };

  const handleCoordinatorselection = (selectedValues) => {
    setSelectedCoordinators(selectedValues);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let fieldName = [];
    if (!supportId.length) {
      fieldName.push("Support Id");
    }
    if (selectedCourses.length === 0) {
      fieldName.push("Selected Courses");
    }
    if (selectedCoordinators.length === 0) {
      fieldName.push("Selected Coordinators");
    }
    if (fieldName.length) {
      for (const field of fieldName) {
        toast.error(field + " is Required!");
      }
      isError = true;
    }
    if (isError) {
      return;
    }
    if (mode === "assign") {
      assignSupportTickets();
    } else {
      updateAssignedSupportTickets();
    }
  };

  const assignSupportTickets = () => {
    const newSupportTicket = {
      id: supportId,
      selectedCourses: selectedCourses,
      selectedCoordinators: selectedCoordinators,
    };
    setSupportTickets((prevSupportTickets) => [
      ...prevSupportTickets,
      newSupportTicket,
    ]);
    clearFields();
  };
  const clearFields = () => {
    setSupportId("");
    setSelectedCourses([]);
    setSelectedCoordinators([]);
    setMode("assign");
  };
  const editAssignedSupportTicket = (id) => {
    setMode("edit");
    setActiveId(id);
    const supportTicket = supportTickets.find(
      (supportTicket) => supportTicket.id === id
    );
    setSupportId(supportTicket.id);
    setSelectedCourses(supportTicket.selectedCourses);
    setSelectedCoordinators(supportTicket.selectedCoordinators);
  };
  const deleteAssignedSupportTickets = (id) => {
    const newSupportTicket = supportTickets.filter(
      (supportTicket) => supportTicket.id !== id
    );
    setSupportTickets(newSupportTicket);
    clearFields();
  };
  const updateAssignedSupportTickets = () => {
    const supportTicket = supportTickets.find(
      (supportTicket) => supportTicket.id === activeId
    );
    supportTicket.id = supportId;
    supportTicket.selectedCourses = selectedCourses;
    supportTicket.selectedCoordinators = selectedCoordinators;
    clearFields();
  };

  return (
    <div className="dashboard__content__wraper">
      <div className="dashboard__section__title">
        <h4>Assign Support Tickets</h4>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="row">
            <div className="col-xl-6">
              <div className="dashboard__form__wraper">
                <div className="dashboard__form__input">
                  <label>Support Ticket ID</label>
                  <input
                    type="text"
                    onChange={(e) => setSupportId(e.target.value)}
                    value={supportId}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="dashboard__form__wraper">
                <div className="dashboard__form__input">
                  <label>Course</label>
                  <Select
                    isMulti={false}
                    options={courses}
                    value={selectedCourses}
                    onChange={handleCourseSelection}
                    placeholder="Select Course"
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="dashboard__form__wraper">
                <div className="dashboard__form__input">
                  <label>Coordinator</label>
                  <Select
                    isMulti={false}
                    options={coordinators}
                    value={selectedCoordinators}
                    onChange={handleCoordinatorselection}
                    placeholder="Select Coordinator"
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="dashboard__form__button pull-right">
                <Link
                  className="default__button"
                  onClick={(e) => handleSubmit(e)}
                >
                  {mode === "assign" ? "Assign" : "Edit"} Support Tickets
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {supportTickets.length > 0 && (
        <div className="row">
          <div className="col-xl-12 mt-5">
            <div className="dashboard__table table-responsive">
              <table>
                <thead>
                  <tr>
                    <th>Support Ticket ID</th>
                    <th>Course</th>
                    <th>Coordinator</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {supportTickets.map((supportTicket) => (
                    <tr key={supportTicket.id}>
                      <td>
                        <p>{supportTicket.id}</p>
                      </td>
                      <td>
                        <p>{supportTicket.selectedCourses.value}</p>
                      </td>
                      <td>
                        <p>{supportTicket.selectedCoordinators.value}</p>
                      </td>
                      <td>
                        <div className="dashboard__button__group">
                          <Link
                            className="dashboard__small__btn"
                            onClick={() =>
                              editAssignedSupportTicket(supportTicket.id)
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-edit"
                            >
                              <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                              <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
                            </svg>{" "}
                            Edit
                          </Link>
                          <Link
                            className="dashboard__small__btn"
                            onClick={() =>
                              deleteAssignedSupportTickets(supportTicket.id)
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-trash-2"
                            >
                              <polyline points="3 6 5 6 21 6" />
                              <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                              <line x1={10} y1={11} x2={10} y2={17} />
                              <line x1={14} y1={11} x2={14} y2={17} />
                            </svg>{" "}
                            Delete
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AssignSupportTickets;
