import React, { useState } from "react";
import { toast } from "react-toastify";

function AssessmentCreation() {
  const [mode, setMode] = useState("create");
  const [id, setId] = useState(1);
  const [activeId, setActiveId] = useState(-1);
  const [exams, setExams] = useState([]);
  const [examName, setExamName] = useState("");
  const [examDuration, setExamDuration] = useState("");

  const [questions, setQuestions] = useState([]);
  const [newQuestion, setNewQuestion] = useState("");
  const [newOptions, setNewOptions] = useState(["", "", "", ""]);
  const [newCorrectAnswer, setNewCorrectAnswer] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const requiredFields = [];

    if (!examName.length) {
      requiredFields.push("Exam Name");
    }
    if (!examDuration.length) {
      requiredFields.push("Exam Duration");
    }
    if (questions.some((question) => question.question.trim() === "")) {
      requiredFields.push("Question");
    }
    if (questions.some((question) => !question.options.length)) {
      requiredFields.push("Options");
    }
    if (questions.some((question) => !question.correctAnswer.trim())) {
      requiredFields.push("Correct Answer");
    }

    if (requiredFields.length > 0) {
      for (const field of requiredFields) {
        toast.error(`${field} is Required!`);
      }
      return;
    }

    if (questions.forEach((question) => !question.options.length)) {
      toast.error("All Options are required");
      return;
    }

    if (mode === "create") {
      createExam();
    } else if (mode === "edit") {
      updateExam();
    }
  };

  const createExam = () => {
    const newExam = {
      id: id,
      examName: examName,
      examDuration: examDuration,
      questions: questions,
    };

    setExams((prevExams) => [...prevExams, newExam]);

    clearFields();
    setId(id + 1);
  };

  const addQuestion = () => {
    if (newQuestion) {
      setQuestions((prevQuestions) => [
        ...prevQuestions,
        {
          question: newQuestion,
          options: newOptions,
          correctAnswer: newCorrectAnswer,
        },
      ]);
      setNewQuestion("");
      setNewOptions(["", "", "", ""]);
      setNewCorrectAnswer("");
    }
  };

  const clearFields = () => {
    setExamName("");
    setExamDuration("");
    setNewQuestion("");
    setNewOptions(["", "", "", ""]);
    setNewCorrectAnswer("");
    setQuestions([]);
  };

  const editExam = (id) => {
    setMode("edit");
    setActiveId(id);
    const exam = exams.find((exam) => exam.id === id);
    setExamName(exam.examName);
    setExamDuration(exam.examDuration);
    setQuestions(exam.questions);
  };

  const deleteExam = (id) => {
    const newExamList = exams.filter((exam) => exam.id !== id);
    setExams(newExamList);
  };

  const updateExam = () => {
    const updatedExam = exams.map((exam) => {
      if (exam.id === activeId) {
        return {
          ...exam,
          examName,
          examDuration,
        };
      }
      return exam;
    });

    setExams(updatedExam);
    setMode("create");
    setActiveId(-1);
    clearFields();
  };

  return (
    <div className="dashboard__content__wraper">
      <div className="dashboard__section__title">
        <h4>Assessment Creation</h4>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="row">
            <div className="col-xl-6">
              <div className="dashboard__form__wraper">
                <div className="dashboard__form__input">
                  <label>Assessment Name</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setExamName(e.target.value)}
                    value={examName}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="dashboard__form__wraper">
                <div className="dashboard__form__input">
                  <label>Exam Duration</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setExamDuration(e.target.value)}
                    value={examDuration}
                  />
                </div>
              </div>
            </div>
            {questions.map((question, index) => (
              <div>
                <div className="col-xl-6" key={index}>
                  <div className="dashboard__form__wraper">
                    <div className="dashboard__form__input">
                      <label>Question {index + 1}</label>
                      <input
                        type="text"
                        className="form-control"
                        value={question.question}
                      />
                    </div>
                  </div>
                </div>
                <label>Options</label>
                {question.options.map((option, optionIndex) => (
                  <div className="col-xl-6">
                    <div className="dashboard__form__wraper">
                      <div className="dashboard__form__input">
                        <input
                          type="text"
                          className="form-control"
                          value={option}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                ))}
                <div className="col-xl-6">
                  <div className="dashboard__form__wraper">
                    <div className="dashboard__form__input">
                      <label>Correct Answer</label>
                      <input
                        type="text"
                        className="form-control"
                        value={question.correctAnswer}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="row">
              <div className="col-xl-6">
                <div className="dashboard__form__wraper">
                  <div className="dashboard__form__input">
                    <label>Question {questions.length + 1}</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => setNewQuestion(e.target.value)}
                      value={newQuestion}
                    />
                  </div>
                </div>
              </div>
              <label className="mont-font fw-600 font-xsss">Options</label>
              {newOptions.map((option, index) => (
                <div key={index} className="form-group mt-2">
                  <input
                    type="text"
                    name={`option-${index}`}
                    className="form-control"
                    value={option}
                    onChange={(e) => {
                      const updatedOptions = [...newOptions];
                      updatedOptions[index] = e.target.value;
                      setNewOptions(updatedOptions);
                    }}
                  />
                </div>
              ))}
              <div className="form-group mt-4">
                <label>Correct Answer</label>
                <input
                  type="text"
                  name="correct-answer"
                  className="form-control"
                  onChange={(e) => setNewCorrectAnswer(e.target.value)}
                  value={newCorrectAnswer}
                />
              </div>
              <div className="form-group mt-4">
                <button onClick={addQuestion} className="btn btn-primary">
                  Add Question
                </button>
              </div>
            </div>
            <div className="row">
              <div className="dashboard__form__button">
                <button
                  className="btn btn-primary pull-right"
                  type="submit"
                  onClick={(e) => handleSubmit(e)}
                >
                  {mode === "create" ? "Create" : "Edit"} Exam
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {exams.length > 0 && (
        <div className="row">
          <div className="col-xl-12 mt-5">
            <div className="dashboard__table table-responsive">
              <table>
                <thead>
                  <tr>
                    <th>Exam ID</th>
                    <th>Exam Name</th>
                    <th>Exam Duration</th>
                    <th>Questions</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {exams.map((exam) => (
                    <tr key={exam.id}>
                      <td>{exam.id}</td>
                      <td>{exam.examName}</td>
                      <td>{exam.examDuration}</td>
                      <td>
                        <ul>
                          {exam.questions.map((question, index) => (
                            <li key={index}>
                              <strong>Question {index + 1}:</strong>{" "}
                              {question.question}
                              <br />
                              <strong>Options:</strong>{" "}
                              {question.options.join(", ")}
                              <br />
                              <strong>Correct Answer:</strong>{" "}
                              {question.correctAnswer}
                              <br />
                              <br />
                            </li>
                          ))}
                        </ul>
                      </td>
                      <td className="text-center">
                        <i
                          className="feather-edit text-current mr-sm-2 mr-md-3 mr-lg-4"
                          style={{ cursor: "pointer" }}
                          onClick={() => editExam(exam.id)}
                        />
                        <i
                          className="feather-trash text-red"
                          style={{ cursor: "pointer" }}
                          onClick={() => deleteExam(exam.id)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AssessmentCreation;
