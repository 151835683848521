import { useState, useEffect } from "react";

function DashboardTop() {
  const username = localStorage.getItem("username");
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setDate(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="dashboardarea__wraper">
              <div className="dashboardarea__img">
                <div className="dashboardarea__inner admin__dashboard__inner">
                  <div className="dashboardarea__left">
                    <div className="dashboardarea__left__content">
                      <h5>Hello,</h5>
                      <h4>{username}</h4>
                    </div>
                  </div>
                  <div className="dashboardarea__right">
                    <div className="dashboardarea__right__content">
                      <h5 className="best__categories__name text-white">
                        {date.toLocaleDateString(undefined, {
                          weekday: "long",
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                      </h5>
                      <h4 className="best__categories__name text-white pull-right text-uppercase">
                        {date.toLocaleTimeString([], { hour12: true })}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardTop;
