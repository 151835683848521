import { Link } from "react-router-dom";

export default function Courses() {
  return (
    <div className="coursearea sp_top_100 sp_bottom_100">
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="course__text__wraper" data-aos="fade-up">
              <div className="course__text">
                <p>Showing 1–12 of 54 Results</p>
              </div>
              <div className="course__icon">
                <ul
                  className="nav property__team__tap"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <Link
                      className="single__tab__link active"
                      data-bs-toggle="tab"
                      data-bs-target="#projects__one"
                    >
                      <i className="icofont-layout" />
                    </Link>
                  </li>
                  <li className="nav-item" role="presentation">
                    <Link
                      className="single__tab__link "
                      data-bs-toggle="tab"
                      data-bs-target="#projects__two"
                    >
                      <i className="icofont-listine-dots" />
                    </Link>
                  </li>
                  <li className="short__by__new">
                    <select
                      className="form-select"
                      aria-label="Default select example"
                    >
                      <option selected> Short by New</option>
                      <option value={1}>One</option>
                      <option value={2}>Two</option>
                      <option value={3}>Three</option>
                    </select>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-xl-12">
            <div
              className="tab-content tab__content__wrapper"
              id="myTabContent"
            >
              <div
                className="tab-pane fade  active show"
                id="projects__one"
                role="tabpanel"
                aria-labelledby="projects__one"
              >
                <div className="row">
                  <div
                    className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12"
                    data-aos="fade-up"
                  >
                    <div className="gridarea__wraper gridarea__wraper__2">
                      <div className="gridarea__img">
                        <Link to="/course-details">
                          <img src="img/grid/grid_1.png" alt="grid" />
                        </Link>
                        <div className="gridarea__small__button">
                          <div className="grid__badge">Data &amp; Tech</div>
                        </div>
                        <div className="gridarea__small__icon">
                          <Link>
                            <i className="icofont-heart-alt" />
                          </Link>
                        </div>
                      </div>
                      <div className="gridarea__content">
                        <div className="gridarea__list">
                          <ul>
                            <li>
                              <i className="icofont-book-alt" /> 23 Lesson
                            </li>
                            <li>
                              <i className="icofont-clock-time" /> 1 hr 30 min
                            </li>
                          </ul>
                        </div>
                        <div className="gridarea__heading">
                          <h3>
                            <Link to="/course-details">
                              Foundation course to under stand about softwere
                            </Link>
                          </h3>
                        </div>
                        <div className="gridarea__price">
                          $32.00 <del>/ $67.00</del>
                          <span>
                            {" "}
                            <del className="del__2">Free</del>
                          </span>
                        </div>
                        <div className="gridarea__bottom">
                          <Link to="instructor-details.html">
                            <div className="gridarea__small__img">
                              <img src="img/grid/grid_small_1.jpg" alt="grid" />
                              <div className="gridarea__small__content">
                                <h6>Mirnsdo .H</h6>
                              </div>
                            </div>
                          </Link>
                          <div className="gridarea__star">
                            <i className="icofont-star" />
                            <i className="icofont-star" />
                            <i className="icofont-star" />
                            <i className="icofont-star" />
                            <span>(44)</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12"
                    data-aos="fade-up"
                  >
                    <div className="gridarea__wraper gridarea__wraper__2">
                      <div className="gridarea__img">
                        <img src="img/grid/grid_2.png" alt="grid" />
                        <div className="gridarea__small__button">
                          <div className="grid__badge blue__color">
                            Mechanical
                          </div>
                        </div>
                        <div className="gridarea__small__icon">
                          <Link>
                            <i className="icofont-heart-alt" />
                          </Link>
                        </div>
                      </div>
                      <div className="gridarea__content">
                        <div className="gridarea__list">
                          <ul>
                            <li>
                              <i className="icofont-book-alt" /> 29 Lesson
                            </li>
                            <li>
                              <i className="icofont-clock-time" /> 2 hr 10 min
                            </li>
                          </ul>
                        </div>
                        <div className="gridarea__heading">
                          <h3>
                            <Link>
                              Nidnies course to under stand about softwere
                            </Link>
                          </h3>
                        </div>
                        <div className="gridarea__price green__color">
                          $32.00<del>/$67.00</del>
                          <span>.Free</span>
                        </div>
                        <div className="gridarea__bottom">
                          <Link to="instructor-details.html">
                            <div className="gridarea__small__img">
                              <img src="img/grid/grid_small_2.jpg" alt="grid" />
                              <div className="gridarea__small__content">
                                <h6>Rinis Jhon</h6>
                              </div>
                            </div>
                          </Link>
                          <div className="gridarea__star">
                            <i className="icofont-star" />
                            <i className="icofont-star" />
                            <i className="icofont-star" />
                            <i className="icofont-star" />
                            <span>(44)</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12"
                    data-aos="fade-up"
                  >
                    <div className="gridarea__wraper gridarea__wraper__2">
                      <div className="gridarea__img">
                        <Link to="/course-details">
                          <img src="img/grid/grid_3.png" alt="grid" />
                        </Link>
                        <div className="gridarea__small__button">
                          <div className="grid__badge pink__color">
                            Development
                          </div>
                        </div>
                        <div className="gridarea__small__icon">
                          <Link>
                            <i className="icofont-heart-alt" />
                          </Link>
                        </div>
                      </div>
                      <div className="gridarea__content">
                        <div className="gridarea__list">
                          <ul>
                            <li>
                              <i className="icofont-book-alt" /> 25 Lesson
                            </li>
                            <li>
                              <i className="icofont-clock-time" /> 1 hr 40 min
                            </li>
                          </ul>
                        </div>
                        <div className="gridarea__heading">
                          <h3>
                            <Link to="/course-details">
                              Minws course to under stand about solution
                            </Link>
                          </h3>
                        </div>
                        <div className="gridarea__price">
                          $40.00 <del>/ $67.00</del>
                          <span>
                            {" "}
                            <del className="del__2">Free</del>
                          </span>
                        </div>
                        <div className="gridarea__bottom">
                          <Link to="instructor-details.html">
                            <div className="gridarea__small__img">
                              <img src="img/grid/grid_small_3.jpg" alt="grid" />
                              <div className="gridarea__small__content">
                                <h6>Micle Jhon</h6>
                              </div>
                            </div>
                          </Link>
                          <div className="gridarea__star">
                            <i className="icofont-star" />
                            <i className="icofont-star" />
                            <i className="icofont-star" />
                            <i className="icofont-star" />
                            <span>(44)</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12"
                    data-aos="fade-up"
                  >
                    <div className="gridarea__wraper gridarea__wraper__2">
                      <div className="gridarea__img">
                        <Link to="/course-details">
                          <img src="img/grid/grid_4.png" alt="grid" />
                        </Link>
                        <div className="gridarea__small__button">
                          <div className="grid__badge green__color">
                            Ui &amp; UX Design
                          </div>
                        </div>
                        <div className="gridarea__small__icon">
                          <Link>
                            <i className="icofont-heart-alt" />
                          </Link>
                        </div>
                      </div>
                      <div className="gridarea__content">
                        <div className="gridarea__list">
                          <ul>
                            <li>
                              <i className="icofont-book-alt" /> 36 Lesson
                            </li>
                            <li>
                              <i className="icofont-clock-time" /> 3 hr 40 min
                            </li>
                          </ul>
                        </div>
                        <div className="gridarea__heading">
                          <h3>
                            <Link to="/course-details">
                              Design course to under stand about solution
                            </Link>
                          </h3>
                        </div>
                        <div className="gridarea__price">
                          $40.00 <del>/ $67.00</del>
                          <span>
                            {" "}
                            <del className="del__2">Free</del>
                          </span>
                        </div>
                        <div className="gridarea__bottom">
                          <Link to="instructor-details.html">
                            <div className="gridarea__small__img">
                              <img src="img/grid/grid_small_4.jpg" alt="grid" />
                              <div className="gridarea__small__content">
                                <h6>Micle Robin</h6>
                              </div>
                            </div>
                          </Link>
                          <div className="gridarea__star">
                            <i className="icofont-star" />
                            <i className="icofont-star" />
                            <i className="icofont-star" />
                            <i className="icofont-star" />
                            <span>(44)</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12"
                    data-aos="fade-up"
                  >
                    <div className="gridarea__wraper gridarea__wraper__2">
                      <div className="gridarea__img">
                        <Link to="/course-details">
                          <img src="img/grid/grid_5.png" alt="grid" />
                        </Link>
                        <div className="gridarea__small__button">
                          <div className="grid__badge orange__color">
                            Data &amp; Tech
                          </div>
                        </div>
                        <div className="gridarea__small__icon">
                          <Link>
                            <i className="icofont-heart-alt" />
                          </Link>
                        </div>
                      </div>
                      <div className="gridarea__content">
                        <div className="gridarea__list">
                          <ul>
                            <li>
                              <i className="icofont-book-alt" /> 36 Lesson
                            </li>
                            <li>
                              <i className="icofont-clock-time" /> 3 hr 40 min
                            </li>
                          </ul>
                        </div>
                        <div className="gridarea__heading">
                          <h3>
                            <Link to="/course-details">
                              Data course to under stand about solution
                            </Link>
                          </h3>
                        </div>
                        <div className="gridarea__price">
                          $40.00 <del>/ $67.00</del>
                          <span>
                            {" "}
                            <del className="del__2">Free</del>
                          </span>
                        </div>
                        <div className="gridarea__bottom">
                          <Link to="instructor-details.html">
                            <div className="gridarea__small__img">
                              <img src="img/grid/grid_small_5.jpg" alt="grid" />
                              <div className="gridarea__small__content">
                                <h6>Micle Robin</h6>
                              </div>
                            </div>
                          </Link>
                          <div className="gridarea__star">
                            <i className="icofont-star" />
                            <i className="icofont-star" />
                            <i className="icofont-star" />
                            <i className="icofont-star" />
                            <span>(44)</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12"
                    data-aos="fade-up"
                  >
                    <div className="gridarea__wraper gridarea__wraper__2">
                      <div className="gridarea__img">
                        <Link to="/course-details">
                          <img src="img/grid/grid_6.png" alt="grid" />
                        </Link>
                        <div className="gridarea__small__button">
                          <div className="grid__badge yellow__color">
                            Big Data
                          </div>
                        </div>
                        <div className="gridarea__small__icon">
                          <Link>
                            <i className="icofont-heart-alt" />
                          </Link>
                        </div>
                      </div>
                      <div className="gridarea__content">
                        <div className="gridarea__list">
                          <ul>
                            <li>
                              <i className="icofont-book-alt" /> 30 Lesson
                            </li>
                            <li>
                              <i className="icofont-clock-time" /> 3 hr 40 min
                            </li>
                          </ul>
                        </div>
                        <div className="gridarea__heading">
                          <h3>
                            <Link to="/course-details">
                              Big data to under stand about solution pacage
                            </Link>
                          </h3>
                        </div>
                        <div className="gridarea__price">
                          $40.00 <del>/ $67.00</del>
                          <span>
                            {" "}
                            <del className="del__2">Free</del>
                          </span>
                        </div>
                        <div className="gridarea__bottom">
                          <Link to="instructor-details.html">
                            <div className="gridarea__small__img">
                              <img src="img/grid/grid_small_5.jpg" alt="grid" />
                              <div className="gridarea__small__content">
                                <h6>Micle Robin</h6>
                              </div>
                            </div>
                          </Link>
                          <div className="gridarea__star">
                            <i className="icofont-star" />
                            <i className="icofont-star" />
                            <i className="icofont-star" />
                            <i className="icofont-star" />
                            <span>(44)</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12"
                    data-aos="fade-up"
                  >
                    <div className="gridarea__wraper gridarea__wraper__2">
                      <div className="gridarea__img">
                        <Link to="/course-details">
                          <img src="img/grid/grid_1.png" alt="grid" />
                        </Link>
                        <div className="gridarea__small__button">
                          <div className="grid__badge">Data &amp; Tech</div>
                        </div>
                        <div className="gridarea__small__icon">
                          <Link>
                            <i className="icofont-heart-alt" />
                          </Link>
                        </div>
                      </div>
                      <div className="gridarea__content">
                        <div className="gridarea__list">
                          <ul>
                            <li>
                              <i className="icofont-book-alt" /> 23 Lesson
                            </li>
                            <li>
                              <i className="icofont-clock-time" /> 1 hr 30 min
                            </li>
                          </ul>
                        </div>
                        <div className="gridarea__heading">
                          <h3>
                            <Link to="/course-details">
                              Foundation course to under stand about softwere
                            </Link>
                          </h3>
                        </div>
                        <div className="gridarea__price">
                          $32.00 <del>/ $67.00</del>
                          <span>
                            {" "}
                            <del className="del__2">Free</del>
                          </span>
                        </div>
                        <div className="gridarea__bottom">
                          <Link to="instructor-details.html">
                            <div className="gridarea__small__img">
                              <img src="img/grid/grid_small_1.jpg" alt="grid" />
                              <div className="gridarea__small__content">
                                <h6>Micle Jhon</h6>
                              </div>
                            </div>
                          </Link>
                          <div className="gridarea__star">
                            <i className="icofont-star" />
                            <i className="icofont-star" />
                            <i className="icofont-star" />
                            <i className="icofont-star" />
                            <i className="icofont-star" />
                            <span>(44)</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12"
                    data-aos="fade-up"
                  >
                    <div className="gridarea__wraper gridarea__wraper__2">
                      <div className="gridarea__img">
                        <img src="img/grid/grid_2.png" alt="grid" />
                        <div className="gridarea__small__button">
                          <div className="grid__badge blue__color">
                            Mechanical
                          </div>
                        </div>
                        <div className="gridarea__small__icon">
                          <Link>
                            <i className="icofont-heart-alt" />
                          </Link>
                        </div>
                      </div>
                      <div className="gridarea__content">
                        <div className="gridarea__list">
                          <ul>
                            <li>
                              <i className="icofont-book-alt" /> 29 Lesson
                            </li>
                            <li>
                              <i className="icofont-clock-time" /> 2 hr 10 min
                            </li>
                          </ul>
                        </div>
                        <div className="gridarea__heading">
                          <h3>
                            <Link>
                              Nidnies course to under stand about softwere
                            </Link>
                          </h3>
                        </div>
                        <div className="gridarea__price green__color">
                          $32.00<del>/$67.00</del>
                          <span>.Free</span>
                        </div>
                        <div className="gridarea__bottom">
                          <Link to="instructor-details.html">
                            <div className="gridarea__small__img">
                              <img src="img/grid/grid_small_2.jpg" alt="grid" />
                              <div className="gridarea__small__content">
                                <h6>Rinis Jhon</h6>
                              </div>
                            </div>
                          </Link>
                          <div className="gridarea__star">
                            <i className="icofont-star" />
                            <i className="icofont-star" />
                            <i className="icofont-star" />
                            <i className="icofont-star" />
                            <span>(44)</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12"
                    data-aos="fade-up"
                  >
                    <div className="gridarea__wraper gridarea__wraper__2">
                      <div className="gridarea__img">
                        <Link to="/course-details">
                          <img src="img/grid/grid_3.png" alt="grid" />
                        </Link>
                        <div className="gridarea__small__button">
                          <div className="grid__badge pink__color">
                            Development
                          </div>
                        </div>
                        <div className="gridarea__small__icon">
                          <Link>
                            <i className="icofont-heart-alt" />
                          </Link>
                        </div>
                      </div>
                      <div className="gridarea__content">
                        <div className="gridarea__list">
                          <ul>
                            <li>
                              <i className="icofont-book-alt" /> 25 Lesson
                            </li>
                            <li>
                              <i className="icofont-clock-time" /> 1 hr 40 min
                            </li>
                          </ul>
                        </div>
                        <div className="gridarea__heading">
                          <h3>
                            <Link to="/course-details">
                              Minws course to under stand about solution
                            </Link>
                          </h3>
                        </div>
                        <div className="gridarea__price">
                          $40.00 <del>/ $67.00</del>
                          <span>
                            {" "}
                            <del className="del__2">Free</del>
                          </span>
                        </div>
                        <div className="gridarea__bottom">
                          <Link to="instructor-details.html">
                            <div className="gridarea__small__img">
                              <img src="img/grid/grid_small_3.jpg" alt="grid" />
                              <div className="gridarea__small__content">
                                <h6>Micle Jhon</h6>
                              </div>
                            </div>
                          </Link>
                          <div className="gridarea__star">
                            <i className="icofont-star" />
                            <i className="icofont-star" />
                            <i className="icofont-star" />
                            <i className="icofont-star" />
                            <span>(44)</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="projects__two"
                role="tabpanel"
                aria-labelledby="projects__two"
              >
                <div
                  className="gridarea__wraper gridarea__wraper__2 gridarea__course__list"
                  data-aos="fade-up"
                >
                  <div className="gridarea__img">
                    <Link to="/course-details">
                      <img src="img/grid/grid_1.png" alt="grid" />
                    </Link>
                    <div className="gridarea__small__button">
                      <div className="grid__badge">Data &amp; Tech</div>
                    </div>
                    <div className="gridarea__small__icon">
                      <Link>
                        <i className="icofont-heart-alt" />
                      </Link>
                    </div>
                  </div>
                  <div className="gridarea__content">
                    <div className="gridarea__list">
                      <ul>
                        <li>
                          <i className="icofont-book-alt" /> 23 Lesson
                        </li>
                        <li>
                          <i className="icofont-clock-time" /> 1 hr 30 min
                        </li>
                      </ul>
                    </div>
                    <div className="gridarea__heading">
                      <h3>
                        <Link to="/course-details">
                          Become a product Manager learn the skills &amp; job.
                        </Link>
                      </h3>
                    </div>
                    <div className="gridarea__price">
                      $32.00 <del>/ $67.00</del>
                      <span>Free.</span>
                    </div>
                    <div className="gridarea__bottom">
                      <div className="gridarea__bottom__left">
                        <Link to="instructor-details.html">
                          <div className="gridarea__small__img">
                            <img src="img/grid/grid_small_1.jpg" alt="grid" />
                            <div className="gridarea__small__content">
                              <h6>Mirnsdo .H</h6>
                            </div>
                          </div>
                        </Link>
                        <div className="gridarea__star">
                          <i className="icofont-star" />
                          <i className="icofont-star" />
                          <i className="icofont-star" />
                          <i className="icofont-star" />
                          <span>(44)</span>
                        </div>
                      </div>
                      <div className="gridarea__details">
                        <Link to="/course-details">
                          Know Details
                          <i className="icofont-arrow-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="gridarea__wraper gridarea__wraper__2 gridarea__course__list"
                  data-aos="fade-up"
                >
                  <div className="gridarea__img">
                    <img src="img/grid/grid_2.png" alt="grid" />
                    <div className="gridarea__small__button">
                      <div className="grid__badge blue__color">Mechanical</div>
                    </div>
                    <div className="gridarea__small__icon">
                      <Link>
                        <i className="icofont-heart-alt" />
                      </Link>
                    </div>
                  </div>
                  <div className="gridarea__content">
                    <div className="gridarea__list">
                      <ul>
                        <li>
                          <i className="icofont-book-alt" /> 23 Lesson
                        </li>
                        <li>
                          <i className="icofont-clock-time" /> 1 hr 30 min
                        </li>
                      </ul>
                    </div>
                    <div className="gridarea__heading">
                      <h3>
                        <Link to="/course-details">
                          Foundation course to under stand about softwere
                        </Link>
                      </h3>
                    </div>
                    <div className="gridarea__price">
                      $32.00 <del>/ $67.00</del>
                      <span>Free.</span>
                    </div>
                    <div className="gridarea__bottom">
                      <div className="gridarea__bottom__left">
                        <Link to="instructor-details.html">
                          <div className="gridarea__small__img">
                            <img src="img/grid/grid_small_1.jpg" alt="grid" />
                            <div className="gridarea__small__content">
                              <h6>Mirnsdo .H</h6>
                            </div>
                          </div>
                        </Link>
                        <div className="gridarea__star">
                          <i className="icofont-star" />
                          <i className="icofont-star" />
                          <i className="icofont-star" />
                          <i className="icofont-star" />
                          <span>(44)</span>
                        </div>
                      </div>
                      <div className="gridarea__details">
                        <Link to="/course-details">
                          Know Details
                          <i className="icofont-arrow-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="gridarea__wraper gridarea__wraper__2 gridarea__course__list"
                  data-aos="fade-up"
                >
                  <div className="gridarea__img">
                    <Link to="/course-details">
                      <img src="img/grid/grid_3.png" alt="grid" />
                    </Link>
                    <div className="gridarea__small__button">
                      <div className="grid__badge pink__color">Development</div>
                    </div>
                    <div className="gridarea__small__icon">
                      <Link>
                        <i className="icofont-heart-alt" />
                      </Link>
                    </div>
                  </div>
                  <div className="gridarea__content">
                    <div className="gridarea__list">
                      <ul>
                        <li>
                          <i className="icofont-book-alt" /> 23 Lesson
                        </li>
                        <li>
                          <i className="icofont-clock-time" /> 1 hr 30 min
                        </li>
                      </ul>
                    </div>
                    <div className="gridarea__heading">
                      <h3>
                        <Link to="/course-details">
                          Strategy law and with for organization Foundation
                        </Link>
                      </h3>
                    </div>
                    <div className="gridarea__price">
                      $32.00 <del>/ $67.00</del>
                      <span>Free.</span>
                    </div>
                    <div className="gridarea__bottom">
                      <div className="gridarea__bottom__left">
                        <Link to="instructor-details.html">
                          <div className="gridarea__small__img">
                            <img src="img/grid/grid_small_1.jpg" alt="grid" />
                            <div className="gridarea__small__content">
                              <h6>Mirnsdo .H</h6>
                            </div>
                          </div>
                        </Link>
                        <div className="gridarea__star">
                          <i className="icofont-star" />
                          <i className="icofont-star" />
                          <i className="icofont-star" />
                          <i className="icofont-star" />
                          <span>(44)</span>
                        </div>
                      </div>
                      <div className="gridarea__details">
                        <Link to="/course-details">
                          Know Details
                          <i className="icofont-arrow-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="gridarea__wraper gridarea__wraper__2 gridarea__course__list"
                  data-aos="fade-up"
                >
                  <div className="gridarea__img">
                    <Link to="/course-details">
                      <img src="img/grid/grid_4.png" alt="grid" />
                    </Link>
                    <div className="gridarea__small__button">
                      <div className="grid__badge green__color">
                        Ui &amp; UX Design
                      </div>
                    </div>
                    <div className="gridarea__small__icon">
                      <Link>
                        <i className="icofont-heart-alt" />
                      </Link>
                    </div>
                  </div>
                  <div className="gridarea__content">
                    <div className="gridarea__list">
                      <ul>
                        <li>
                          <i className="icofont-book-alt" /> 23 Lesson
                        </li>
                        <li>
                          <i className="icofont-clock-time" /> 1 hr 30 min
                        </li>
                      </ul>
                    </div>
                    <div className="gridarea__heading">
                      <h3>
                        <Link to="/course-details">
                          The business Intelligence analyst with Course &amp;
                          2023
                        </Link>
                      </h3>
                    </div>
                    <div className="gridarea__price">
                      $32.00 <del>/ $67.00</del>
                      <span>Free.</span>
                    </div>
                    <div className="gridarea__bottom">
                      <div className="gridarea__bottom__left">
                        <Link to="instructor-details.html">
                          <div className="gridarea__small__img">
                            <img src="img/grid/grid_small_1.jpg" alt="grid" />
                            <div className="gridarea__small__content">
                              <h6>Mirnsdo .H</h6>
                            </div>
                          </div>
                        </Link>
                        <div className="gridarea__star">
                          <i className="icofont-star" />
                          <i className="icofont-star" />
                          <i className="icofont-star" />
                          <i className="icofont-star" />
                          <span>(44)</span>
                        </div>
                      </div>
                      <div className="gridarea__details">
                        <Link to="/course-details">
                          Know Details
                          <i className="icofont-arrow-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="gridarea__wraper gridarea__wraper__2 gridarea__course__list"
                  data-aos="fade-up"
                >
                  <div className="gridarea__img">
                    <Link to="/course-details">
                      <img src="img/grid/grid_5.png" alt="grid" />
                    </Link>
                    <div className="gridarea__small__button">
                      <div className="grid__badge orange__color">
                        Data &amp; Tech
                      </div>
                    </div>
                    <div className="gridarea__small__icon">
                      <Link>
                        <i className="icofont-heart-alt" />
                      </Link>
                    </div>
                  </div>
                  <div className="gridarea__content">
                    <div className="gridarea__list">
                      <ul>
                        <li>
                          <i className="icofont-book-alt" /> 23 Lesson
                        </li>
                        <li>
                          <i className="icofont-clock-time" /> 1 hr 30 min
                        </li>
                      </ul>
                    </div>
                    <div className="gridarea__heading">
                      <h3>
                        <Link to="/course-details">
                          Become a product Manager learn the skills &amp; job.
                        </Link>
                      </h3>
                    </div>
                    <div className="gridarea__price">
                      $32.00 <del>/ $67.00</del>
                      <span>Free.</span>
                    </div>
                    <div className="gridarea__bottom">
                      <div className="gridarea__bottom__left">
                        <Link to="instructor-details.html">
                          <div className="gridarea__small__img">
                            <img src="img/grid/grid_small_1.jpg" alt="grid" />
                            <div className="gridarea__small__content">
                              <h6>Mirnsdo .H</h6>
                            </div>
                          </div>
                        </Link>
                        <div className="gridarea__star">
                          <i className="icofont-star" />
                          <i className="icofont-star" />
                          <i className="icofont-star" />
                          <i className="icofont-star" />
                          <span>(44)</span>
                        </div>
                      </div>
                      <div className="gridarea__details">
                        <Link to="/course-details">
                          Know Details
                          <i className="icofont-arrow-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="main__pagination__wrapper" data-aos="fade-up">
            <ul className="main__page__pagination">
              <li>
                <Link className="disable" href="#">
                  <i className="icofont-double-left" />
                </Link>
              </li>
              <li>
                <Link className="active" href="#">
                  1
                </Link>
              </li>
              <li>
                <Link>2</Link>
              </li>
              <li>
                <Link>3</Link>
              </li>
              <li>
                <Link>
                  <i className="icofont-double-right" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
