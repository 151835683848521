import { Link } from "react-router-dom";

export default function Header() {
  const authToken = localStorage.getItem("authToken");
  const userRole = localStorage.getItem("userRole");

  return (
    <header>
      <div className="headerarea headerarea__3 header__sticky header__area">
        <div className="container desktop__menu__wrapper">
          <div className="row">
            <div className="col-xl-2 col-lg-2 col-md-6">
              <div className="headerarea__left">
                <div className="headerarea__left__logo">
                  <Link to="/">
                    <img src="/img/logo/logo.png" alt="logo" height={"49px"} />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-7 col-lg-7 main_menu_wrap">
              <div className="headerarea__main__menu">
                <nav>
                  <ul>
                    <li className="mega__menu position-static">
                      <Link to="/about">About Us</Link>
                    </li>
                    <li className="mega__menu position-static">
                      <Link to="/courses">Courses</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                    <li>
                      <Link to="/blog">Blog</Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              {authToken && userRole ? (
                <div className="headerarea__right">
                  <div className="headerarea__login mt-2 mb-2">
                    <Link to={"/dashboard/" + userRole}>
                      <i className="icofont-user-alt-5" />
                      &nbsp;&nbsp;Dashboard
                    </Link>
                  </div>
                </div>
              ) : (
                <div className="headerarea__right">
                  <div className="headerarea__login">
                    <Link to="/auth/login">Login</Link>
                  </div>
                  <div className="headerarea__button">
                    <Link to="/auth/sign-up">Sign Up</Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="container-fluid mob_menu_wrapper">
          <div className="row align-items-center">
            <div className="col-6">
              <div className="mobile-logo">
                <Link to="/" className="logo__dark">
                  <img src="/img/logo/logo.png" alt="logo" height={"32px"} />
                </Link>
              </div>
            </div>
            <div className="col-6">
              <div className="header-right-wrap">
                <div className="headerarea__right">
                  <div className="header__cart">
                    <div className="header__right__dropdown__wrapper">
                      <div className="header__right__dropdown__inner">
                        <div className="single__header__right__dropdown">
                          <div className="header__right__dropdown__img">
                            <Link>
                              <img src="/img/grid/cart1.jpg" alt="cart1" />
                            </Link>
                          </div>
                          <div className="header__right__dropdown__content">
                            <Link to="shop-product.html">Web Directory</Link>
                            <p>
                              1 x <span className="price">$ 80.00</span>
                            </p>
                          </div>
                          <div className="header__right__dropdown__close">
                            <Link>
                              <i className="icofont-close-line" />
                            </Link>
                          </div>
                        </div>
                        <div className="single__header__right__dropdown">
                          <div className="header__right__dropdown__img">
                            <Link>
                              <img src="/img/grid/cart2.jpg" alt="cart2" />
                            </Link>
                          </div>
                          <div className="header__right__dropdown__content">
                            <Link to="shop-product.html">Design Minois</Link>
                            <p>
                              1 x <span className="price">$ 60.00</span>
                            </p>
                          </div>
                          <div className="header__right__dropdown__close">
                            <Link>
                              <i className="icofont-close-line" />
                            </Link>
                          </div>
                        </div>
                        <div className="single__header__right__dropdown">
                          <div className="header__right__dropdown__img">
                            <Link>
                              <img src="/img/grid/cart3.jpg" alt="cart3" />
                            </Link>
                          </div>
                          <div className="header__right__dropdown__content">
                            <Link to="shop-product.html">Crash Course</Link>
                            <p>
                              1 x <span className="price">$ 70.00</span>
                            </p>
                          </div>
                          <div className="header__right__dropdown__close">
                            <Link>
                              <i className="icofont-close-line" />
                            </Link>
                          </div>
                        </div>
                      </div>
                      <p className="dropdown__price">
                        Total: <span>$1,100.00</span>
                      </p>
                      <div className="header__right__dropdown__button">
                        <Link className="white__color">VIEW CART</Link>
                        <Link className="blue__color">CHECKOUT</Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mobile-off-canvas">
                  <Link className="mobile-aside-button">
                    <i className="icofont-navigation-menu" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
