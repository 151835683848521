import { Link } from "react-router-dom";

function QAActions() {
  const userRole = localStorage.getItem("userRole");

  return (
    <div className="dashboard__content__wraper">
      <div className="dashboard__section__title">
        <h4>Dashboard</h4>
      </div>
      <div className="row">
        <div className="col-xl-6 col-lg-12 col-md-12 col-12">
          <div className="dashboard__content__wraper admin__content__wrapper">
            <div className="dashboard__popular__instructor">
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link
                      to={"/dashboard/" + userRole + "/curriculum-evaluation"}
                    >
                      Curriculum Evalutation
                    </Link>
                  </h4>
                </div>
              </div>
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link
                      to={"/dashboard/" + userRole + "/performance-assessment"}
                    >
                      Performance Assessment
                    </Link>
                  </h4>
                </div>
              </div>
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link to={"/dashboard/" + userRole + "/qs-compliance"}>
                      QS Compliance
                    </Link>
                  </h4>
                </div>
              </div>
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link
                      to={"/dashboard/" + userRole + "/support-and-enquiries"}
                    >
                      Support & Enquiries
                    </Link>
                  </h4>
                </div>
              </div>
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link
                      to={"/dashboard/" + userRole + "/faculty-development"}
                    >
                      Faculty Development
                    </Link>
                  </h4>
                </div>
              </div>
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link to={"/dashboard/" + userRole + "/assessment-tools"}>
                      Assessment Tools
                    </Link>
                  </h4>
                </div>
              </div>
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link
                      to={"/dashboard/" + userRole + "/improvement-initiatives"}
                    >
                      Improvement Initiatives
                    </Link>
                  </h4>
                </div>
              </div>
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link to={"/dashboard/" + userRole + "/reporting"}>
                      Reporting
                    </Link>
                  </h4>
                </div>
              </div>
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link to={"/dashboard/" + userRole + "/accreditation"}>
                      Accreditation
                    </Link>
                  </h4>
                </div>
              </div>
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link to={"/dashboard/" + userRole + "/reviews"}>
                      Reviews
                    </Link>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QAActions;
