import { Link } from "react-router-dom";

export default function DashContent() {
  return (
    <div className="col-xl-9 col-lg-9 col-md-12">
      <div className="dashboard__content__wraper">
        <div className="dashboard__section__title">
          <h4>Summary</h4>
        </div>
        <div className="row">
          <div className="col-xl-4 col-lg-6 col-md-12 col-12">
            <div className="dashboard__single__counter">
              <div className="counterarea__text__wraper">
                <div className="counter__img">
                  <img src="../img/counter/counter__1.png" alt="counter" />
                </div>
                <div className="counter__content__wraper">
                  <div className="counter__number">
                    <span className="counter">27</span>+
                  </div>
                  <p>Enrolled Courses</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6 col-md-12 col-12">
            <div className="dashboard__single__counter">
              <div className="counterarea__text__wraper">
                <div className="counter__img">
                  <img src="../img/counter/counter__2.png" alt="counter" />
                </div>
                <div className="counter__content__wraper">
                  <div className="counter__number">
                    <span className="counter">08</span>+
                  </div>
                  <p>Active Courses</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6 col-md-12 col-12">
            <div className="dashboard__single__counter">
              <div className="counterarea__text__wraper">
                <div className="counter__img">
                  <img src="../img/counter/counter__3.png" alt="counter" />
                </div>
                <div className="counter__content__wraper">
                  <div className="counter__number">
                    <span className="counter">12</span>
                  </div>
                  <p>Complete Courses</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="dashboard__content__wraper">
        <div className="dashboard__section__title">
          <h4>Feedbacks</h4>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <div className="dashboard__table table-responsive">
              <table>
                <thead>
                  <tr>
                    <th>Course Name</th>
                    <th>Enrolled</th>
                    <th>Rating</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>
                      <Link>Javascript</Link>
                    </th>
                    <td>1100</td>
                    <td>
                      <div className="dashboard__table__star">
                        <i className="icofont-star"></i>
                        <i className="icofont-star"></i>
                        <i className="icofont-star"></i>
                        <i className="icofont-star"></i>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          className="feather feather-star"
                        >
                          <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                        </svg>
                      </div>
                    </td>
                  </tr>
                  <tr className="dashboard__table__row">
                    <th>
                      <Link>PHP</Link>
                    </th>
                    <td>700</td>
                    <td>
                      <div className="dashboard__table__star">
                        <i className="icofont-star"></i>
                        <i className="icofont-star"></i>
                        <i className="icofont-star"></i>
                        <i className="icofont-star"></i>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          className="feather feather-star"
                        >
                          <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                        </svg>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <Link>HTML</Link>
                    </th>
                    <td>1350</td>
                    <td>
                      <div className="dashboard__table__star">
                        <i className="icofont-star"></i>
                        <i className="icofont-star"></i>
                        <i className="icofont-star"></i>
                        <i className="icofont-star"></i>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          className="feather feather-star"
                        >
                          <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                        </svg>
                      </div>
                    </td>
                  </tr>
                  <tr className="dashboard__table__row">
                    <th>
                      <Link>Graphic</Link>
                    </th>
                    <td>1266</td>
                    <td>
                      <div className="dashboard__table__star">
                        <i className="icofont-star"></i>
                        <i className="icofont-star"></i>
                        <i className="icofont-star"></i>
                        <i className="icofont-star"></i>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          className="feather feather-star"
                        >
                          <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                        </svg>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
