import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const grades = [
  { value: "A+", label: "A+" },
  { value: "A", label: "A" },
  { value: "A-", label: "A-" },
  { value: "B+", label: "B+" },
  { value: "B", label: "B" },
  { value: "B-", label: "B-" },
  { value: "C+", label: "C+" },
  { value: "C", label: "C" },
  { value: "C-", label: "C-" },
  { value: "D+", label: "D+" },
  { value: "D", label: "D" },
  { value: "D-", label: "D-" },
  { value: "F", label: "F" },
];

function GradeStudent() {
  const [courseId, setCourseId] = useState("");
  const [courseName, setCourseName] = useState("");
  const [selectedGrade, setSelectedGrade] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const requiredFields = [];

    if (!courseId.length) {
      requiredFields.push("Course ID");
    }
    if (!courseName.length) {
      requiredFields.push("Course Name");
    }

    if (requiredFields.length > 0) {
      for (const field of requiredFields) {
        toast.error(`${field} is Required!`);
      }
      return;
    }
  };

  const handleGradeSelection = (selectedValues) => {
    setSelectedGrade(selectedValues);
  };

  return (
    <div className="dashboard__content__wraper">
      <div className="dashboard__section__title">
        <h4>Grade Student</h4>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="row">
            <div className="col-xl-6">
              <div className="dashboard__form__wraper">
                <div className="dashboard__form__input">
                  <label>Course ID</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setCourseId(e.target.value)}
                    value={courseId}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="dashboard__form__wraper">
                <div className="dashboard__form__input">
                  <label>Course Duration</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setCourseName(e.target.value)}
                    value={courseName}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-12 mt-4">
              <div className="dashboard__form__button pull-right">
                <Link onClick={handleSubmit} className="default__button">
                  Filter Students
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12 mt-5">
          <div className="dashboard__table table-responsive">
            <table>
              <thead>
                <tr>
                  <th>Student ID</th>
                  <th>Student Name</th>
                  <th>Course</th>
                  <th>Assign Grade</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>John Doe</td>
                  <td>Algorithms</td>
                  <td className="text-center">
                    <select className="form-control" name="grades" id="grades">
                      <option value="">Grade</option>
                      {grades.map((grade) => (
                        <option
                          key={grade.value}
                          value={grade.value}
                          onClick={handleGradeSelection}
                          selected={grade.value === selectedGrade}
                        >
                          {grade.label}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GradeStudent;
