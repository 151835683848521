import { Link } from "react-router-dom";

function AdminActions() {
  return (
    <div className="dashboard__content__wraper">
      <div className="dashboard__section__title">
        <h4>Dashboard</h4>
      </div>
      <div className="row">
        <div className="col-xl-4 col-lg-6 col-md-12 col-12">
          <div className="dashboard__single__counter">
            <div className="counterarea__text__wraper">
              <div className="counter__img">
                <img src="../img/counter/counter__1.png" alt="counter" />
              </div>
              <div className="counter__content__wraper">
                <div className="counter__number">
                  <span className="counter">900</span>+
                </div>
                <p>Enrolled Courses</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-6 col-md-12 col-12">
          <div className="dashboard__single__counter">
            <div className="counterarea__text__wraper">
              <div className="counter__img">
                <img src="../img/counter/counter__2.png" alt="counter" />
              </div>
              <div className="counter__content__wraper">
                <div className="counter__number">
                  <span className="counter">500</span>+
                </div>
                <p>Active Courses</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-6 col-md-12 col-12">
          <div className="dashboard__single__counter">
            <div className="counterarea__text__wraper">
              <div className="counter__img">
                <img src="../img/counter/counter__3.png" alt="counter" />
              </div>
              <div className="counter__content__wraper">
                <div className="counter__number">
                  <span className="counter">300</span>k
                </div>
                <p>Complete Courses</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-6 col-md-12 col-12">
          <div className="dashboard__single__counter">
            <div className="counterarea__text__wraper">
              <div className="counter__img">
                <img src="../img/counter/counter__4.png" alt="counter" />
              </div>
              <div className="counter__content__wraper">
                <div className="counter__number">
                  <span className="counter">1500</span>+
                </div>
                <p>Total Courses</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-6 col-md-12 col-12">
          <div className="dashboard__single__counter">
            <div className="counterarea__text__wraper">
              <div className="counter__img">
                <img src="../img/counter/counter__3.png" alt="counter" />
              </div>
              <div className="counter__content__wraper">
                <div className="counter__number">
                  <span className="counter">30</span>k
                </div>
                <p>Total Students</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-6 col-md-12 col-12">
          <div className="dashboard__single__counter">
            <div className="counterarea__text__wraper">
              <div className="counter__img">
                <img src="../img/counter/counter__4.png" alt="counter" />
              </div>
              <div className="counter__content__wraper">
                <div className="counter__number">
                  <span className="counter">90,000</span>K+
                </div>
                <p>Total Earning</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-6 col-lg-12 col-md-12 col-12">
          <div className="dashboard__content__wraper admin__content__wrapper">
            <div className="dashboard__section__title">
              <h4>Students</h4>
            </div>
            <div className="dashboard__popular__instructor">
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link to="/dashboard/admin/manage-students">
                      Manage Students
                    </Link>
                  </h4>
                </div>
              </div>
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link to="/dashboard/admin/manage-courses">
                      Manage Courses
                    </Link>
                  </h4>
                </div>
              </div>

              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link to="/dashboard/admin/reports-and-performance">
                      Reports & Performance
                    </Link>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-12 col-md-12 col-12">
          <div className="dashboard__content__wraper admin__content__wrapper">
            <div className="dashboard__section__title">
              <h4>QA Officers</h4>
            </div>
            <div className="dashboard__popular__instructor">
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link to="/dashboard/admin/manage-qa-officers">
                      Manage QA Officers
                    </Link>
                  </h4>
                </div>
              </div>
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link to="/dashboard/admin/assign-course">
                      Assign Course
                    </Link>
                  </h4>
                </div>
              </div>
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link to="/dashboard/admin/reports-and-performance">
                      Reports & Performance
                    </Link>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-12 col-md-12 col-12">
          <div className="dashboard__content__wraper admin__content__wrapper">
            <div className="dashboard__section__title">
              <h4>Instructors</h4>
            </div>
            <div className="dashboard__popular__instructor">
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link to="/dashboard/admin/manage-instructors">
                      Manage Instructors
                    </Link>
                  </h4>
                </div>
              </div>
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link to="/dashboard/admin/handle-courses">
                      Handle Courses
                    </Link>
                  </h4>
                </div>
              </div>
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link to="/dashboard/admin/assign-students">
                      Assign Students
                    </Link>
                  </h4>
                </div>
              </div>
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link to="/dashboard/admin/reviews">Reviews</Link>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-12 col-md-12 col-12">
          <div className="dashboard__content__wraper admin__content__wrapper">
            <div className="dashboard__section__title">
              <h4>Coordinator</h4>
            </div>
            <div className="dashboard__popular__instructor">
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link to="/dashboard/admin/manage-coordinators">
                      Manage Coordinators
                    </Link>
                  </h4>
                </div>
              </div>
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link to="/dashboard/admin/handle-programs">
                      Handle Programs
                    </Link>
                  </h4>
                </div>
              </div>
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link to="/dashboard/admin/assign-support-tickets">
                      Assign Support Tickets
                    </Link>
                  </h4>
                </div>
              </div>
              {/* <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link to="/dashboard/admin/analytics">Analytics</Link>
                  </h4>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminActions;
