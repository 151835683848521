import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import validator from "validator";

function ManageUsers(props) {
  const role = props.role;

  let isError = false;
  const [mode, setMode] = useState("add");
  const [id, setId] = useState(1);
  const [activeId, setActiveId] = useState(-1);
  const [users, setUsers] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    let fieldName = [];
    if (!name.length) {
      fieldName.push("Name");
    }
    if (!email.length) {
      fieldName.push("Email");
    }
    if (!phone.length) {
      fieldName.push("Phone");
    }
    if (!address.length) {
      fieldName.push("Address");
    }

    if (fieldName.length) {
      for (const field of fieldName) {
        toast.error(field + " is Required!");
      }
      isError = true;
    }

    if (email.length && !validator.isEmail(email)) {
      toast.error("Email is Invalid!");
      isError = true;
    }

    const phoneNumberValidation = /^[+]?\d+$/;
    if (
      phone.length &&
      !validator.isMobilePhone(phone) &&
      !phoneNumberValidation.test(phone)
    ) {
      toast.error("Phone Number is Invalid!");
      isError = true;
    }

    if (isError) {
      return;
    }
    mode === "add" ? addUser() : updateUser();
  };

  const clearFields = () => {
    setName("");
    setEmail("");
    setPhone("");
    setAddress("");
  };

  const addUser = () => {
    const newUser = {
      id: id,
      name: name,
      email: email,
      phone: phone,
      address: address,
    };

    setUsers((prevUsers) => [...prevUsers, newUser]);

    clearFields();
    setId(id + 1);
  };

  const editUser = (id) => {
    setMode("edit");
    setActiveId(id);
    const user = users.find((user) => user.id === id);
    setName(user.name);
    setEmail(user.email);
    setPhone(user.phone);
    setAddress(user.address);
  };

  const deleteUser = (id) => {
    const newUsers = users.filter((user) => user.id !== id);
    setUsers(newUsers);
  };

  const updateUser = () => {
    const user = users.find((user) => user.id === activeId);
    if (user) {
      user.name = name;
      user.email = email;
      user.phone = phone;
      user.address = address;
    }
    clearFields();
    setMode("add");
  };

  return (
    <div className="dashboard__content__wraper">
      <div className="dashboard__section__title">
        <h4>Manage {role}s</h4>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="row">
            <div className="col-xl-6">
              <div className="dashboard__form__wraper">
                <div className="dashboard__form__input">
                  <label>Name</label>
                  <input
                    type="text"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="dashboard__form__wraper">
                <div className="dashboard__form__input">
                  <label>Email</label>
                  <input
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="dashboard__form__wraper">
                <div className="dashboard__form__input">
                  <label>Phone</label>
                  <input
                    type="tel"
                    pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                    onChange={(e) => setPhone(e.target.value)}
                    value={phone}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="dashboard__form__wraper">
                <div className="dashboard__form__input">
                  <label>Address</label>
                  <textarea
                    name="address"
                    id="address"
                    cols={30}
                    rows={3}
                    onChange={(e) => setAddress(e.target.value)}
                    value={address}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="dashboard__form__button">
                <Link
                  className="default__button"
                  onClick={(e) => handleSubmit(e)}
                >
                  {mode === "add" ? "Add" : "Edit"} {role}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {users.length > 0 && (
        <div className="row">
          <div className="col-xl-12 mt-5">
            <div className="dashboard__table table-responsive">
              <table>
                <thead>
                  <tr>
                    <th>{role} ID</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Address</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user) => (
                    <tr key={user.id}>
                      <td>
                        <p>{user.id}</p>
                      </td>
                      <td>
                        <p>{user.name}</p>
                      </td>
                      <td>
                        <p>{user.email}</p>
                      </td>
                      <td>
                        <p>{user.phone}</p>
                      </td>
                      <td>
                        <p>{user.address}</p>
                      </td>
                      <td>
                        <div className="dashboard__button__group">
                          <Link
                            className="dashboard__small__btn__2"
                            onClick={() => editUser(user.id)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-edit"
                            >
                              <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                              <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
                            </svg>
                            &nbsp; Edit
                          </Link>
                          <Link
                            className="dashboard__small__btn__2 dashboard__small__btn__3"
                            onClick={() => deleteUser(user.id)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-trash-2"
                            >
                              <polyline points="3 6 5 6 21 6" />
                              <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                              <line x1={10} y1={11} x2={10} y2={17} />
                              <line x1={14} y1={11} x2={14} y2={17} />
                            </svg>
                            &nbsp; Delete
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ManageUsers;
