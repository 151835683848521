import { Outlet, useNavigate } from "react-router-dom";
import Navigation from "./Navigation";
import DashboardTop from "./DashboardTop";

function DashboardLayout() {
  useNavigate();
  return (
    <div>
      <div className="body__wrapper">
        <div className="dashboardarea sp_bottom_100 mt-4">
          <DashboardTop />
          <div className="dashboard">
            <div className="container-fluid">
              <div className="row">
                <Navigation />
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardLayout;
