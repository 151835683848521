import { Link, useNavigate, Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import validator from "validator";
import axios from "axios";
import useAuth from "../../../hooks/useAuth";
import BASE_URL from "../../../config";
import { useState } from "react";

export default function Login() {
  const authToken = localStorage.getItem("authToken");
  const userRole = localStorage.getItem("userRole");

  const navigate = useNavigate();
  const baseUrl = BASE_URL;

  const { setAuth } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  let isError = false;
  const handleSubmit = (e) => {
    e.preventDefault();

    let fieldName = [];
    if (!email.length) {
      fieldName.push("Email");
    }
    if (!password.length) {
      fieldName.push("Password ");
    }
    if (fieldName.length) {
      for (const field of fieldName) {
        toast.error(field + " is Required!");
      }
      isError = true;
    }

    if (email.length && !validator.isEmail(email)) {
      toast.error("Email is Invalid!");
      isError = true;
    }

    if (isError) {
      return;
    }
    loginUser();
  };

  function loginUser() {
    axios
      .post(baseUrl + "/login", {
        email: email,
        password: password,
      })
      .then((response) => {
        const data = response.data;
        localStorage.setItem("username", data.name);
        localStorage.setItem("authToken", data.token);
        localStorage.setItem("userRole", data.role);
        setAuth({
          username: `${data.name}`,
          authToken: `${data.token}`,
          userRole: `${data.role}`,
        });
        toast.success("Logged In Successfully!");
        navigate("/dashboard/" + data.role, { replace: true });
      })
      .catch(function (error) {
        toast.error(error.response?.data?.message);
      });
  }

  return authToken && userRole ? (
    <Navigate to={"/dashboard/" + userRole} />
  ) : (
    <div className="loginarea sp_top_100 sp_bottom_100">
      <div className="container">
        <div className="row">
          <div
            className="col-xl-8 col-md-8 offset-md-2"
            data-aos="fade-up"
          ></div>
          <div
            className="tab-content tab__content__wrapper"
            id="myTabContent"
            data-aos="fade-up"
          >
            <div className="col-xl-8 col-md-8 offset-md-2">
              <div className="loginarea__wraper">
                <div className="login__heading">
                  <h5 className="login__title">Login</h5>
                  <p className="login__description">
                    Don't have an account yet?{" "}
                    <Link to="/auth/sign-up">Sign up for free</Link>
                  </p>
                </div>
                <form action="#">
                  <div className="login__form">
                    <label className="form__label">Email</label>
                    <input
                      className="common__login__input"
                      type="email"
                      placeholder="Email"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                  </div>
                  <div className="login__form">
                    <label className="form__label">Password</label>
                    <input
                      className="common__login__input"
                      type="password"
                      placeholder="Password"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                    />
                  </div>
                  <div className="login__form d-flex justify-content-between flex-wrap gap-2">
                    <div className="form__check">
                      <label htmlFor="forgot"></label>
                    </div>
                    <div className="text-end login__form__link">
                      <Link to="/auth/forgot-your-password">
                        Forgot your password?
                      </Link>
                    </div>
                  </div>
                  <div className="login__button">
                    <Link
                      onClick={(e) => handleSubmit(e)}
                      className="default__button"
                    >
                      Log In
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className=" login__shape__img educationarea__shape_image">
          <img
            className="hero__shape hero__shape__1"
            src="/img/education/hero_shape2.png"
            alt="Shape"
          />
          <img
            className="hero__shape hero__shape__2"
            src="/img/education/hero_shape3.png"
            alt="Shape"
          />
          <img
            className="hero__shape hero__shape__3"
            src="/img/education/hero_shape4.png"
            alt="Shape"
          />
          <img
            className="hero__shape hero__shape__4"
            src="/img/education/hero_shape5.png"
            alt="Shape"
          />
        </div>
      </div>
    </div>
  );
}
