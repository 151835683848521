import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

function CourseManagement() {
  let isError = false;
  const [mode, setMode] = useState("create");
  const [id, setId] = useState(1);
  const [activeId, setActiveId] = useState(-1);
  const [courses, setCourses] = useState([]);
  const [courseName, setCourseName] = useState("");
  const [courseDuration, setCourseDuration] = useState("");
  const [courseDescription, setCourseDescription] = useState("");
  const [courseCost, setCourseCost] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    let fieldName = [];
    if (!courseName.length) {
      fieldName.push("Course Name");
    }
    if (!courseDuration.length) {
      fieldName.push("Course Duration");
    }
    if (!courseDescription.length) {
      fieldName.push("Course Description");
    }
    if (!courseCost.length) {
      fieldName.push("Course Cost");
    }

    if (fieldName.length) {
      for (const field of fieldName) {
        toast.error(field + " is Required!");
      }
      isError = true;
    }
    if (isError) {
      return;
    }
    mode === "create" ? createCourse() : updateCourse();
  };

  const clearFields = () => {
    setCourseName("");
    setCourseDuration("");
    setCourseDescription("");
    setCourseCost("");
    setMode("create");
  };

  const createCourse = () => {
    const newCourse = {
      id: id,
      courseName: courseName,
      courseDuration: courseDuration,
      courseDescription: courseDescription,
      courseCost: courseCost,
    };

    setCourses((prevCourses) => [...prevCourses, newCourse]);

    clearFields();
    setId(id + 1);
  };

  const editCourse = (id) => {
    setMode("edit");
    setActiveId(id);
    const course = courses.find((course) => course.id === id);
    setCourseName(course.courseName);
    setCourseDuration(course.courseDuration);
    setCourseDescription(course.courseDescription);
    setCourseCost(course.courseCost);
  };

  const deleteCourse = (id) => {
    const newCourse = courses.filter((course) => course.id !== id);
    setCourses(newCourse);
  };

  const updateCourse = () => {
    const course = courses.find((course) => course.id === activeId);
    course.courseName = courseName;
    course.courseDuration = courseDuration;
    course.courseDescription = courseDescription;
    course.courseCost = courseCost;

    clearFields();
  };

  return (
    <div className="dashboard__content__wraper">
      <div className="dashboard__section__title">
        <h4>Course Management</h4>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="row">
            <div className="col-xl-6">
              <div className="dashboard__form__wraper">
                <div className="dashboard__form__input">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setCourseName(e.target.value)}
                    value={courseName}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="dashboard__form__wraper">
                <div className="dashboard__form__input">
                  <label>Duration</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setCourseDuration(e.target.value)}
                    value={courseDuration}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="dashboard__form__wraper">
                <div className="dashboard__form__input">
                  <label>Description</label>
                  <textarea
                    cols={30}
                    rows={3}
                    onChange={(e) => setCourseDescription(e.target.value)}
                    value={courseDescription}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="dashboard__form__wraper">
                <div className="dashboard__form__input">
                  <label>Cost</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setCourseCost(e.target.value)}
                    value={courseCost}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-12 mt-4">
              <div className="dashboard__form__button pull-right">
                <Link
                  className="default__button"
                  onClick={(e) => handleSubmit(e)}
                >
                  {mode === "create" ? "Create" : "Edit"} Course
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {courses.length > 0 && (
        <div className="row">
          <div className="col-xl-12 mt-5">
            <div className="dashboard__table table-responsive">
              <table>
                <thead>
                  <tr>
                    <th>Course ID</th>
                    <th>Course Name</th>
                    <th>Course Duration</th>
                    <th>Course Description</th>
                    <th>Course Cost</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {courses.map((course) => (
                    <tr key={course.id}>
                      <td>{course.id}</td>
                      <td>{course.courseName}</td>
                      <td>{course.courseDuration}</td>
                      <td>{course.courseDescription}</td>
                      <td>{course.courseCost}</td>
                      <td>
                        <div className="dashboard__button__group">
                          <Link
                            className="dashboard__small__btn"
                            onClick={() => editCourse(course.id)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-edit"
                            >
                              <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                              <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
                            </svg>{" "}
                            Edit
                          </Link>
                          <Link
                            className="dashboard__small__btn"
                            onClick={() => deleteCourse(course.id)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-trash-2"
                            >
                              <polyline points="3 6 5 6 21 6" />
                              <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                              <line x1={10} y1={11} x2={10} y2={17} />
                              <line x1={14} y1={11} x2={14} y2={17} />
                            </svg>{" "}
                            Delete
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CourseManagement;
